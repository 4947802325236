@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

.price {
  color: $red-700;
  font-weight: 900;
  font-size: 21px;
  letter-spacing: -0.1px;
  white-space: nowrap;

  @include media($min-xs) {
    font-size: 26px;
  }

  @include media($min-md) {
    font-size: 30px;
  }
}

.secondary-price {
  @extend .price;

  font-size: 20px;
  color: $dt-dark-gray;
}

.unit {
  color: $grey-800;
  font-size: 14px;
  font-weight: $bold;
  letter-spacing: -0.06px;
  padding-left: 2px;
  text-transform: lowercase;
}

.from {
  color: $dark-gray;
  font-size: 14px;
  font-weight: 600;
}
