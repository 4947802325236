@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base';

.wrapper {
  position: fixed;
  width: 100%;
  border-bottom: 1px solid $dt-nav-light-gray;
  background: $white;
  z-index: 10;
}

.container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 11px 20px;
  max-width: $content-max-width-xl;

  @include media($min-sm) {
    padding: 11px 40px;
  }

  @include media($min-md) {
    padding: 18px 40px;
  }

  @include media($min-lg) {
    padding: 20px 40px;
  }

  @include media($min-xl) {
    padding: 20px 0px;
  }

  & :global .help-menu {
    width: 182px;
  }
}

.site-logo {
  max-width: 75px;

  @include media($min-md) {
    max-width: 120px;
  }

  @include media($min-lg) {
    max-width: 150px;
  }
}

.need-help {
  display: flex;
  align-items: center;
  padding: 10px 0 10px 10px;
  background: $white;
  font-size: rem(14);
}

.icon {
  margin-left: 16px;
  font-size: rem(18);
  color: $medium-light-gray;
  pointer-events: none;
}

.list {
  font-size: rem(14);
  text-align: center;
}

.list-item {
  border-bottom: 1px solid $dt-medium-light-gray;

  &:last-child {
    border: 0;
  }
}

.help-option {
  display: block;
  padding: 19px;
}

.help-option-icon {
  margin-right: 12px;
  color: $dark-gray;
}

.help-menu {
  border: 1px solid $grey-500;

  :global {
    .popover__pointer {
      border: 1px solid $medium-light-gray;
      background: $white;
    }
    .popover__child-wrapper {
      background: $white;
    }
  }
}
