@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

.container {
  display: inline-block;
  white-space: nowrap;
}

.rating-value {
  margin-left: 0.4em;
  color: $grey-600;
}

.stars-container {
  display: inline-block;
  position: relative;
}

.stars-empty {
  color: $grey-300;

  svg {
    margin-right: 0.15em;
  }

  svg:last-child {
    margin: 0;
  }
}

.stars-fill {
  position: absolute;
  left: 0;
  color: $gold-500;
  white-space: nowrap;
  overflow: hidden;

  svg {
    margin-right: 0.15em;
  }

  svg:last-child {
    margin: 0;
  }
}
