@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../../../shared/scss/dt-base';

.nav-section {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  min-height: 40px;
  border-bottom: 1px solid $dt-nav-light-gray;

  @include media($min-sm) {
    padding: 0;
    height: 50%;
  }
}

.nav-section--no-border {
  border: 0;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-link {
  padding: 12px 10px;
  background: transparent;
  color: $dark-gray;
  font-weight: 700;
  font-size: rem(11);
  text-transform: uppercase;

  @include media($min-md) {
    font-size: rem(12);
  }
  @include media($min-lg) {
    padding: 12px;
  }
}

.selected-menu {
  font-weight: $bolder;
  border-bottom: 2px solid $black;
}
