@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

%empty {
  display: block;
  position: relative;
  height: 20px;
  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: 0;
    width: 100%;
    height: 14px;
    background: $light-gray;
  }
}

.container {
  text-transform: capitalize;
  display: flex;
  flex-wrap: wrap;
  padding-left: 24px;
}

.address {
  margin-top: 5px;
  color: $medium-gray;
  font-size: rem(16);
}

.my-store {
  @extend .address;
  margin-left: 18px;
}

.my-store--hidden-marker-title {
  @extend .my-store;
  margin-left: 5px;
  margin-top: -2px;
  line-height: 24px;
}

.line1 {
  &:empty {
    width: 90%;
    @extend %empty;
  }
}

.city {
  &:empty {
    width: 65%;
    @extend %empty;
  }
}

.phone {
  display: block;
  margin-top: 10px;
  font-size: rem(16);

  &:empty {
    width: 105px;
    height: 23px;
    @extend %empty;
  }
  color: $dark-gray;
}

.phone--hidden-marker-title {
  @extend .phone;
  margin-top: 0;
  font-size: rem(16);
}

.star-rating {
  padding-bottom: 10px;
}

.icon {
  width: 15px;
  height: 15px;
  color: $dt-red;
  margin: 0px 2px;
}

.reviews-value {
  margin-left: 7px;
  text-transform: none;
  font-size: rem(13);
}

.store-code {
  font-size: rem(14);
  line-height: rem(15);
  color: $dt-black;

  @include media($min-sm) {
    color: $dark-gray;
  }
}
