@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base';

:global input:invalid {
  box-shadow: none;
}

.default {
  position: relative;
  border-radius: 3px;
  width: 100%;
  color: $black;

  .icon {
    margin: 1px 5px 0 0;
    font-size: rem(12.25);
    color: $black;
  }
}

.input-container {
  height: 48px;
  border-radius: 4px;
  overflow: hidden;
  border: 1px solid $grey-500;
  background: $white;

  .input-label-disabled {
    color: $grey-500;
  }
}

.input-container-error {
  @extend .input-container;
  border-color: $gold-500;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  input {
    border-radius: 3.2px 3.2px 0 0;
  }
}

.input-container-info {
  @extend .input-container;
  border-color: $blue-700;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-container--disabled {
  border-color: $grey-100;
  background: $grey-100;
}

.input-container--focused {
  outline-offset: -1px;
}

.messages {
  display: flex;
  flex: 1 1 auto;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  overflow: hidden;
  min-width: 120px;
}

.error-message {
  display: flex;
  margin: -1px 0 0;
  padding: 8px 24px 8px 12px;
  background: $gold-500;
  color: $black;
  width: 100%;
  align-items: baseline;
  white-space: pre-line;
  text-align: left;
}

.error-message-line2 {
  display: block;
  font-weight: $normal;
}

.info-message {
  margin: -1px 0 0;
  padding: 8px 24px 8px 12px;
  background: $blue-700;
  color: $white;
  width: 100%;
}

:global .react-selectize.root-node .react-selectize-control {
  position: relative;
  display: flex;
  cursor: pointer;

  .react-selectize-toggle-button-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 40px;
    height: 53px;
    cursor: pointer;
    z-index: 1;
  }
}

:global
  .react-selectize.default.root-node
  .react-selectize-search-field-and-selected-values {
  display: flex;
  flex-grow: 1;
  padding: 11px 0 13px 20px;
  font-weight: $bold;
  z-index: 1;

  .resizable-input {
    font-weight: $bold;
    font-size: rem(16);
  }
}
