@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base';

%link {
  display: block;
  padding: 10px;
  font-size: rem(14);
  font-weight: 100;
  cursor: pointer;
}

.container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  :global {
    .input-container {
      border-radius: 0;
      border: 0;
    }

    .organization-details {
      margin-top: 25px;
    }
  }
}

.submenu-wrapper {
  overflow: auto;
}

.submenu {
  padding: 0 20px 20px;
}

.submenu-label {
  display: block;
  margin-top: 15px;
  font-size: rem(15);
  font-weight: bold;
}

.submenu-option {
  border-bottom: 1px solid $dt-medium-light-gray;

  &:last-child {
    border: none;
  }
}

.submenu-link {
  @extend %link;
  color: $dt-medium-gray;
}

.view-all-link {
  @extend %link;
  color: $dt-blue;
}

.options {
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 100%;
  background: $white;
  animation: menu-anim 0.35s ease-out forwards;
}

.overlay {
  flex-grow: 1;
  background: linear-gradient(rgba(73, 73, 73, 0.95), rgba($dark-gray, 0.65));
  font-size: 24px;
}

.list {
  width: 100%;
}

.list-head {
  position: relative;
  border-bottom: 1px solid $dt-black;

  :global .search-icon {
    position: absolute;
    right: 15px;
    top: 15px;
    color: $dt-medium-gray;
  }
}

.list-item {
  border-top: 1px solid $dt-medium-light-gray;
}

.link {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background: $white;
  color: $dt-black;
  font-size: rem(16);
  cursor: pointer;
}

.search-bar {
  width: 100%;
  height: 50px;
  font-weight: 400;
  background: #f4f4f4;
}

.back-to {
  width: 100%;
  padding: 15px;
  font-weight: bold;
  font-size: rem(14);
  color: $dt-medium-gray;
  text-align: left;
  background: #f4f4f4;
  cursor: pointer;
}

.back-to-icon {
  margin-right: 5px;
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 30px;
  color: $dt-black;
}

@keyframes menu-anim {
  0% {
    margin-left: -250px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    margin-left: 0;
  }
}

.store-information {
  :global {
    .mobile-header-store-view {
      flex-direction: column;
      border-bottom: none;
      padding-left: 15px;
    }

    .mobile-header-store-view__send-phone {
      margin: 10px 0px;
      padding-left: 17px;
      font-size: rem(14);
    }
  }
}

.account {
  font-weight: 600;
  font-size: rem(16);
}

.organization {
  margin-top: 20px;
  color: $dt-black;
}

.company-name {
  margin-left: 8px;
}
