@mixin __FF_ORDER_VERSIONING__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_MAP_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WINTER_TREADWELL__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_LUG_NUT_ADD_EDIT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_DISABLE_UNUSED_ROUTES__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SAME_DAY_VOID__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TERMINAL_CUSTOMER_PRINTOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../../shared/scss/dt-base';

.container {
  display: none;
  position: absolute;
  top: 91px;
  left: 0;
  width: 100%;
  padding: 25px 25px 35px;
  background: $white;
  font-size: rem(12);
  font-weight: 500;
  border: 1px solid $dt-nav-light-gray;
  border-top: 0;

  @include media($min-sm) {
    display: flex;
    top: 112px;
  }

  @include media($min-md) {
    left: 192px;
  }

  @include media($min-lg) {
    width: 950px;
  }
}

.container--extended-links {
  top: 162px;
}

.main-link {
  display: block;
  width: 150px;
  margin-top: 10px;
  padding: 11px;
  background: $dt-light-medium-gray;
  text-transform: uppercase;
  text-align: center;
  color: $dt-medium-gray;
  font-size: rem(12);
  line-height: 1.45;
}

.category {
  margin: 0px 10px 15px 40px;
}

.category-name {
  display: block;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
}

.category-list-link {
  display: block;
  margin-bottom: 10px;
  color: $dt-medium-gray;
  font-size: rem(12);
  line-height: 1.45;
}

.view-all-link {
  display: block;
  margin-top: 18px;
  font-size: rem(12);
  line-height: 1.45;
}

.category-links {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @include media($min-lg) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 750px;
  }
}
